import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
/* import logo from "../../assets/img/nbcbank_light2.svg"; */
import logo from "../../assets/img/logo_nbcbank_light.svg";
import FooterList from "./ListFooter";

const Footer = () => {
  const [offset, setOffset] = useState(0);
  const urlLocation = useLocation();

  /* Code for the 'scroll up' button */
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      {urlLocation.pathname.split("/").length <= 4 && (
        <footer className="footer">
          <div className="container px-lg-4">
            <div className="row mb-lg-2">
              <div className="col-lg-6 col-12 px-0 py-4">
                <Link to="/">
                  <img
                    className="footer-logo"
                    loading="lazy"
                    src={logo}
                    alt="Logo NBCBANK"
                  />
                </Link>
              </div>
            </div>
            <FooterList />
          </div>
          <a
            href="#navbarNav"
            className={`${
              offset > 150 ? "opacity-100" : "opacity-0"
            } bg-azul-nbc fs-1 rounded-circle d-flex justify-content-start align-items-center scroll-up-btn text-decoration-none nbc-shadow`}
          >
            <i className="fas fa-angle-up text-white fs-2"></i>
          </a>
        </footer>
      )}
    </>
  );
};

export default Footer;
