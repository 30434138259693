import { useNavbar } from "../../hooks/useNavbar";
import { Link, useLocation } from "react-router-dom";
import NavBarList from "./NavBarList";
import HamburgerMenu from "../layouts/elements/HamburgerMenu";
import logo from "../../assets/img/logo_nbcbank_light.svg";
//import { LoaderPage } from "../loading/LoaderPage";

const NavBar = () => {
  let { fetchedNavbarItems, offsetY } = useNavbar();
  let urlLocation = useLocation();

  return (
    <>
      {(urlLocation.pathname.split("/").length <= 4 || urlLocation.hash || urlLocation.pathname.includes("fitchSection")) && (
        <div className="header" style={{ zIndex: 5 }}>
          <nav
            className={`navbar position-relative bg-azul-nbc navbar-expand-xl ${
              offsetY > 650 && "menu-transition-color"
            }`}
          >
            <div className="container px-3 px-lg-1">
              <Link className="navbar-brand" to="/">
                <img className="navbar-logo" src={logo} alt="Logo NBCBANK" />
              </Link>
              <HamburgerMenu />
              <div className="navbar-items-container">
                <div className="container-fluid p-0 m-0">
                  <ul className="navbar-nav">
                    <NavBarList navbarItems={fetchedNavbarItems} />
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default NavBar;
