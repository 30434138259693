// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/style.css";
import "./assets/css/style-btn-hambur.css";

import Routes from "./Routes";
import Cookies from "./components/cookies/Cookies";
import { AbraContaPJProvider } from "./context/AbraContaContext";

function App() {
  return (
    <>
      <AbraContaPJProvider>
        <Routes />
      </AbraContaPJProvider>
      <Cookies />
    </>
  );
}

export default App;
